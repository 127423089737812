import React from 'react';

const AdvisorFAQ = () => ([
    {
      title: 'What does a mortgage broker do?',
      description: (
        <>
        A mortgage broker, or mortgage adviser, is someone who helps you find the right deal for either your remortgage or a first mortgage deal.<br /><br />
        Mortgage brokers manage the relationship and exchange between you (the borrower) and a mortgage lender.<br /><br />
        A mortgage broker will be able to help chat through your financial situation and understand your preferences so that you can find the best deal on your mortgage.<br /><br />
        Using a mortgage broker is a great idea to give you peace of mind that you're choosing the right mortgage deal for your circumstance, with the best interest rate, mortgage term and type of mortgage.
        </>
      ),
    },
    {
      title: 'How much does a mortgage broker cost?',
      description: (
        <>
          There are often fees involved in using a mortgage broker. Mortgage broker fees vary from adviser to adviser, and can also depend on your own mortgage requirements and the amount you want to borrow for the mortgage loan. Usually, the mortgage broker costs are around £500.<br/><br/>
          However, there is such a thing as a fee-free mortgage broker where they rely on the commission they receive from lenders.<br /><br />
          It's important to make sure that you do your research and fully understand the fees associated with a mortgage broker.
        </>
      ),
    },
    {
      title: 'What are the benefits of using mortgage brokers?',
      description: (
        <>
          Remortgaging and taking out a new mortgage can be complicated so this can help with the mortgage process. If you use a mortgage broker, they can sometimes help make the process quicker and easier.<br/><br/>
          Some mortgage brokers often have access to exclusive mortgage deals because of the relationships that they have with mortgage lenders.<br /><br />
          A mortgage broker can also help reduce your likelihood of getting your mortgage application rejected as they will make sure you have the right documents in place and that the lender will determine if you can afford the mortgage.
        </>
      ),
    },
    {
      title: 'What are the disadvantages of using a mortgage broker?',
      description: (
        <>
          Some mortgage brokers won't have access to the whole mortgage market, which can limit your access to more deals. It's a good idea to ask the broker what lenders they have access to.<br/><br/>
          Using a mortgage broker comes with a broker fee.
        </>
      ),
    },
    {
      title: 'How do you find a mortgage broker?',
      description: (
        <>
          It can be difficult to know how to find the right mortgage broker. Searching online is a good way to compare mortgage providers available.<br/><br/>
          Make sure you're asking the right questions when deciding what mortgage broker to go with, such as their fees, and the lenders they work with to make sure they are not tied to a lender.
        </>
      ),
    },
  ]);

export default AdvisorFAQ;
